import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { IconComponent } from '@client-portal/ui/icon';
import { MatIconButton } from '@angular/material/button';
import { A11yElementClickDirective } from '@client-portal/directives/a11y-element-click';

@Component({
  selector: 'ui-dialog',
  templateUrl: 'dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatDialogModule,
    NgClass,
    IconComponent,
    MatIconButton,
    A11yElementClickDirective,
  ],
})
export class DialogComponent {
  @Input()
  public title: string | null = null;

  @Input()
  public showCloseButton = true;

  @Output()
  public closeDialog = new EventEmitter<MouseEvent | KeyboardEvent>();

  constructor(private readonly _dialogRef: MatDialogRef<DialogComponent>) {
    _dialogRef.addPanelClass('ui-dialog-container');
  }
}
