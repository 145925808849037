<div class="modal-dialog">
  <header [class.no-title]="!title">
    @if (title) {
      <h2 class="title">{{ title }}</h2>
    }
    @if (showCloseButton) {
      <button mat-icon-button mat-dialog-close (click)="closeDialog.emit()">
        <ui-icon icon="close" class="close" />
      </button>
    }
  </header>
  <ng-content select="[mat-dialog-content]"></ng-content>
  <ng-content select="[mat-dialog-actions]"></ng-content>
</div>
